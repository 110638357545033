<template>
  <div class="qrcode">
    <qrcode-stream @decode="onDecode" @init="onInit" />
  </div>
</template>
<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      from: "",
      result: "",
      error: ""
    };
  },
  methods: {
    onDecode(result) {
      this.result = result;
      this.$router.replace({
        name: this.from,
        params: { qrcode: result }
      });
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "请授权打开摄像头";
        } else if (error.name === "NotFoundError") {
          this.error = "未找到摄像头";
        } else if (error.name === "NotSupportedError") {
          this.error = "服务器SSL不支持";
        } else if (error.name === "NotReadableError") {
          this.error = "摄像头正在使用中";
        } else if (error.name === "OverconstrainedError") {
          this.error = "摄像头不合适";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "浏览器不支持此API";
        } else if (error.name === "InsecureContextError") {
          this.error = "仅支持SSL地址";
        } else {
          this.error = `摄像头错误 (${error.name})`;
        }
        Dialog.alert({
          title: "错误",
          message: this.error
        });
      }
    }
  },
  created: function() {
    this.from = this.$route.params.from;
  }
};
</script>

<style lang="scss">
html,
body,
#app,
.qrcode {
  height: 100%;
  width: 100%;
}
</style>
